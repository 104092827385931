<template>
  <Box
    customClass="timetracking py-6 px-4 has-background-white is-shadowless">

    <h3 class="title is-4 has-text-centered">Time Tracking</h3>
    <div class="timetracking_wrap is-flex is-flex-direction-column is-justify-content-center is-align-content-center is-align-items-center">
        <h5  v-if="issue.iid" class="has-text-centered">Task #{{ issue.iid }}</h5>
        <h4  v-if="issue.title" class="title is-5 has-text-centered mt-2 px-4 has-text-weight-medium">{{ issue.title }}</h4>

        <div class="timetracking_counter my-5">
          <div id="timer_counter" class="timer title is-3 has-text-weight-semibold has-text-info" >
              {{ counter }}
          </div>
        </div>

        <div class="timetracking_action">
          <time-tracking-actions :issue="issue" />
        </div>
    </div>
  </Box>
</template>

<script>
import { defineAsyncComponent, onMounted } from 'vue'
import useTimeTracking from '@/use/useTimeTracking'

export default {
  props: {
    propName: {
      type: Number,
      default: 0
    }
  },
  components: {
    Box: defineAsyncComponent(() => import('@/components/ds/Box')),
    TimeTrackingActions: defineAsyncComponent(()=> import('@/components/widgets/timeTracking/actions'))
  },
  setup () {

    const {
      counter,
      issue
    } = useTimeTracking();

    onMounted (() => { })

    return {
      counter,
      issue
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
